import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { GraphQLParams, Job } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  jobs: { items: Job[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class JobsGQL extends Query<Response, GraphQLParams<'job'>> {
  document = gql(config.job.query);
}
