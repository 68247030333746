import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { GraphQLParams, JobTaskLine } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  jobTaskLines: { items: JobTaskLine[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class JobTasksGQL extends Query<Response, GraphQLParams<'jobTask'>> {
  document = gql(config.jobTask.query);
}
